<template>
    <main>
        <section class="hero">
            <img src="@/assets/LP.webp" alt="Banner" class="banner-mobile">
            <img src="@/assets/LP.webp" alt="Banner" class="banner-desktop">

        </section>
        <section class="download">
            <h2 class="download-header2">Faça parte da mesma rede dos atletas pro</h2>
            <h1 class="download-header1">Baixe agora mesmo</h1>
            <div class="store-buttons">
            <div>
                <a href="https://play.google.com/store/apps/details?id=io.kodular.leonardobombonato.WeeBT"
                    target="_blank">
                    <img src="@/assets/googleplay.webp" alt="Google Play Store" class="store-badge">
                </a>
                <a href="https://apps.apple.com/us/app/wee-bt/id6469634932" target="_blank">
                    <img src="@/assets/applestore.webp" alt="App Store" class="store-badge">
                </a>
                </div>
                <a href="https://app.weebt.com.br" target="_blank" class="browser-access">
                    Acesse pelo navegador
                </a>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name: 'HomePage',
    data() {
        return {
            screenshots: [
                { src: 'screenshot1.webp', alt: 'Screenshot 1' },
                { src: 'screenshot2.webp', alt: 'Screenshot 2' },
                { src: 'screenshot3.webp', alt: 'Screenshot 3' },
                { src: 'screenshot4.webp', alt: 'Screenshot 4' },
            ],
            showModal: false,
            activeScreenshot: 0,
        };
    },
    methods: {
        openModal(index) {
            this.activeScreenshot = index;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
    }
};
</script>

<style scoped>
.hero {
  padding: 0;
  max-height: 70vh;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .banner-desktop {
    display: none;
  }
}

@media only screen and (min-width: 601px) {
  .banner-mobile {
    display: none;
  }
}

.banner-mobile {
  width: 100%;
}

.banner-desktop {
  width: 100%;
}

.download {
  padding: 15px 25px;
  color: #683498;
}

.store-buttons {
  padding-top: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.store-badge {
  /* min-width: 150px; */
  /* max-width: 45vw; */
  height: 50px;
}

.browser-access {
  font-size: 1.2em;
}

.download-header1 {
  font-size: 2em;
  margin: 0;
}

.download-header2 {
  font-size: 1em;
  font-weight: normal;
}

.screenshot-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 15px 25px;
}

.screenshot-container img {
  max-width: 45vw;
  max-height: 45vw;
  border-radius: 25px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 80% !important;
  max-height: 80% !important;
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #f1f1f1;
  font-size: 60px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

</style>